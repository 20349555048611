import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDocs,
  collection,
  orderBy,
  limit,
  query,
  where,
} from 'firebase/firestore';
import { db } from '../firebase/firestore'; // Adjust the path as needed
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

const userLevelss = [
  { id: 1, name: 'Rookie', icon: '/hero1.svg', tapBalanceRequired: 50000 },          // 50K tap balance
  { id: 2, name: 'Warrior', icon: '/hero2.svg', tapBalanceRequired: 500000 },         // 500K tap balance
  { id: 3, name: 'Legend', icon: '/hero3.svg', tapBalanceRequired: 1000000 },        // 1M tap balance
  { id: 4, name: 'Gladiator', icon: '/hero4.svg', tapBalanceRequired: 5000000 },        // 5M tap balance
  { id: 5, name: 'Master', icon: '/hero5.svg', tapBalanceRequired: 20000000 },       // 20M tap balance
  { id: 6, name: 'Titan', icon: '/hero6.svg', tapBalanceRequired: 50000000 },       // 50M tap balance
  { id: 7, name: 'King', icon: '/hero7.svg', tapBalanceRequired: 200000000 },      // 200M tap balance
  { id: 8, name: 'Angel', icon: '/hero8.svg', tapBalanceRequired: 500000000 },      // 500M tap balance
  { id: 9, name: 'God', icon: '/hero9.svg', tapBalanceRequired: 1000000000 },     // 1B tap balance
  { id: 10, name: 'Immortal', icon: '/hero10.svg', tapBalanceRequired: 10000000000 }  // 10B tap balance
];

const prTeam = [
  {
    title: 'Gym',
    level: [
      { level: 1, profit: 50, cost: 1500 },
      { level: 2, profit: 150, cost: 3000 },
      { level: 3, profit: 250, cost: 4500 },
      { level: 4, profit: 350, cost: 6000 },
      { level: 5, profit: 450, cost: 9000 },
      { level: 6, profit: 550, cost: 12000 },
      { level: 7, profit: 1650, cost: 15000 },
      { level: 8, profit: 2750, cost: 18000 },
      { level: 9, profit: 5850, cost: 21000 },
      { level: 10, profit: 7950, cost: 30000 },
      { level: 11, profit: 1050, cost: 45000 },
      { level: 12, profit: 2150, cost: 75000 },
      { level: 13, profit: 4250, cost: 120000 },
      { level: 14, profit: 6350, cost: 180000 },
      { level: 15, profit: 7450, cost: 250000 },
    ],
    totalProfit: 0,
    icon: '/gym.png',
    description:
      'Develop the Gym practice needed to navigate volatile markets, making informed decisions that lead to long-term trading success.',
  },
  {
    title: 'Sparring',
    level: [
      { level: 1, profit: 95, cost: 5500 },
      { level: 2, profit: 175, cost: 11000 },
      { level: 3, profit: 255, cost: 22000 },
      { level: 4, profit: 335, cost: 44000 },
      { level: 5, profit: 415, cost: 55000 },
      { level: 6, profit: 495, cost: 66000 },
      { level: 7, profit: 575, cost: 88000 },
      { level: 8, profit: 655, cost: 110000 },
      { level: 9, profit: 735, cost: 132000 },
      { level: 10, profit: 1815, cost: 198000 },
      { level: 11, profit: 3895, cost: 264000 },
      { level: 12, profit: 6975, cost: 330000 },
      { level: 13, profit: 11055, cost: 550000 },
      { level: 14, profit: 21135, cost: 880000 },
      { level: 15, profit: 31215, cost: 1300000 },
    ],
    totalProfit: 0,
    icon: '/sparring.png',
    description:
      'Cultivate the discipline to stick to your trading strategy, minimizing risks and maximizing consistent returns over time.',
  },
  {
    title: 'Jiu Jitsu',
    level: [
      { level: 1, profit: 170, cost: 7300 },
      { level: 2, profit: 235, cost: 14600 },
      { level: 3, profit: 300, cost: 29200 },
      { level: 4, profit: 365, cost: 43800 },
      { level: 5, profit: 430, cost: 58400 },
      { level: 6, profit: 495, cost: 87600 },
      { level: 7, profit: 560, cost: 116800 },
      { level: 8, profit: 625, cost: 146000 },
      { level: 9, profit: 1690, cost: 175200 },
      { level: 10, profit: 3755, cost: 262800 },
      { level: 11, profit: 7820, cost: 350400 },
      { level: 12, profit: 10885, cost: 438000 },
      { level: 13, profit: 27950, cost: 730000 },
      { level: 14, profit: 31015, cost: 1168000 },
      { level: 15, profit: 51080, cost: 1750000 },
    ],
    totalProfit: 0,
    icon: '/jiujitsu.png',
    description:
      'Enhance your ability to adapt to market changes, ensuring your trading strategy remains effective in fluctuating conditions.',
  },
  {
    title: 'Taekwondo',
    level: [
      { level: 1, profit: 350, cost: 18000 },
      { level: 2, profit: 500, cost: 36000 },
      { level: 3, profit: 650, cost: 72000 },
      { level: 4, profit: 800, cost: 108000 },
      { level: 5, profit: 950, cost: 144000 },
      { level: 6, profit: 1100, cost: 216000 },
      { level: 7, profit: 1250, cost: 288000 },
      { level: 8, profit: 5400, cost: 360000 },
      { level: 9, profit: 11550, cost: 432000 },
      { level: 10, profit: 31700, cost: 648000 },
      { level: 11, profit: 51850, cost: 864000 },
      { level: 12, profit: 72000, cost: 1080000 },
      { level: 13, profit: 82150, cost: 1800000 },
      { level: 14, profit: 102300, cost: 2880000 },
      { level: 15, profit: 122450, cost: 4000000 },
    ],
    totalProfit: 0,
    icon: '/Taekwondo.png',
    description:
      'Increase your market awareness, enabling you to anticipate trends and make informed trading decisions with confidence.',
  },
  {
    title: 'Кarate',
    level: [
      { level: 1, profit: 670, cost: 27000 },
      { level: 2, profit: 830, cost: 54000 },
      { level: 3, profit: 990, cost: 108000 },
      { level: 4, profit: 1150, cost: 162000 },
      { level: 5, profit: 3310, cost: 216000 },
      { level: 6, profit: 8470, cost: 324000 },
      { level: 7, profit: 21630, cost: 432000 },
      { level: 8, profit: 51790, cost: 540000 },
      { level: 9, profit: 71950, cost: 648000 },
      { level: 10, profit: 102110, cost: 972000 },
      { level: 11, profit: 152270, cost: 1296000 },
      { level: 12, profit: 172430, cost: 1620000 },
      { level: 13, profit: 252590, cost: 2700000 },
      { level: 14, profit: 272750, cost: 4320000 },
      { level: 15, profit: 332910, cost: 6000000 },
    ],
    totalProfit: 0,
    icon: '/karate.png',
    description:
      'Master technical analysis to predict market movements and make data-driven trading decisions with greater precision.',
  },
  {
    title: 'Muay Thai',
    level: [
      { level: 1, profit: 850, cost: 57000 },
      { level: 2, profit: 970, cost: 114000 },
      { level: 3, profit: 1090, cost: 228000 },
      { level: 4, profit: 1210, cost: 342000 },
      { level: 5, profit: 1330, cost: 456000 },
      { level: 6, profit: 10450, cost: 684000 },
      { level: 7, profit: 31570, cost: 912000 },
      { level: 8, profit: 51690, cost: 1140000 },
      { level: 9, profit: 81810, cost: 1368000 },
      { level: 10, profit: 131930, cost: 2052000 },
      { level: 11, profit: 222050, cost: 2736000 },
      { level: 12, profit: 312170, cost: 3420000 },
      { level: 13, profit: 382290, cost: 5700000 },
      { level: 14, profit: 402410, cost: 9120000 },
      { level: 15, profit: 452530, cost: 12500000 },
    ],
    totalProfit: 0,
    icon: '/MuayThai.png',
    description:
      'Build a strong network within the crypto community, gaining insights and opportunities to enhance your trading performance.',
  },
  {
    title: 'MMA',
    level: [
      { level: 1, profit: 1080, cost: 73000 },
      { level: 2, profit: 1260, cost: 146000 },
      { level: 3, profit: 2440, cost: 292000 },
      { level: 4, profit: 3620, cost: 438000 },
      { level: 5, profit: 8800, cost: 584000 },
      { level: 6, profit: 15980, cost: 876000 },
      { level: 7, profit: 32160, cost: 1168000 },
      { level: 8, profit: 82340, cost: 1460000 },
      { level: 9, profit: 122520, cost: 1752000 },
      { level: 10, profit: 272700, cost: 2628000 },
      { level: 11, profit: 352880, cost: 3504000 },
      { level: 12, profit: 553060, cost: 4380000 },
      { level: 13, profit: 633240, cost: 7300000 },
      { level: 14, profit: 673420, cost: 11680000 },
      { level: 15, profit: 703600, cost: 18000000 },
    ],
    totalProfit: 0,
    icon: '/MMA.png',
    description:
      'Build a strong network within the crypto community, gaining insights and opportunities to enhance your trading performance.',
  },

  {
    title: 'Kung Fu',
    level: [
      { level: 1, profit: 1570, cost: 73000 },
      { level: 2, profit: 2300, cost: 146000 },
      { level: 3, profit: 5030, cost: 292000 },
      { level: 4, profit: 8760, cost: 438000 },
      { level: 5, profit: 24490, cost: 584000 },
      { level: 6, profit: 35220, cost: 876000 },
      { level: 7, profit: 65950, cost: 1168000 },
      { level: 8, profit: 106680, cost: 1460000 },
      { level: 9, profit: 177410, cost: 1752000 },
      { level: 10, profit: 298140, cost: 2628000 },
      { level: 11, profit: 388870, cost: 3504000 },
      { level: 12, profit: 529600, cost: 4380000 },
      { level: 13, profit: 610330, cost: 7300000 },
      { level: 14, profit: 711060, cost: 11680000 },
      { level: 15, profit: 811790, cost: 18000000 },
    ],
    totalProfit: 0,
    icon: '/kungfu.png',
    description:
      'Build a strong network within the crypto community, gaining insights and opportunities to enhance your trading performance.',
  },
];


const marketTeam = [
  {
    title: 'VS. local gang',
    level: [
      { level: 1, profit: 1800, cost: 105000 },
      { level: 2, profit: 3950, cost: 210000 },
      { level: 3, profit: 6100, cost: 420000 },
      { level: 4, profit: 8250, cost: 840000 },
      { level: 5, profit: 10400, cost: 1200000 },
      { level: 6, profit: 12550, cost: 1500000 },
      { level: 7, profit: 14700, cost: 1800000 },
      { level: 8, profit: 26850, cost: 2200000 },
      { level: 9, profit: 59000, cost: 2700000 },
      { level: 10, profit: 211150, cost: 3900000 },
      { level: 11, profit: 323300, cost: 5000000 },
      { level: 12, profit: 525450, cost: 6000000 },
      { level: 13, profit: 627600, cost: 8000000 },
      { level: 14, profit: 829750, cost: 10000000 },
      { level: 15, profit: 931900, cost: 12000000 },
    ],
    totalProfit: 0,
    icon: '/localgang.png',
    description:
      'Guide your team in the crypto space, making strategic decisions that drive growth and secure exclusive market advantages.',
  },
  {
    title: 'VS. country boss',
    level: [
      { level: 1, profit: 5500, cost: 139000 },
      { level: 2, profit: 8300, cost: 278000 },
      { level: 3, profit: 11100, cost: 556000 },
      { level: 4, profit: 13900, cost: 1112000 },
      { level: 5, profit: 36700, cost: 1600000 },
      { level: 6, profit: 59500, cost: 2200000 },
      { level: 7, profit: 72300, cost: 2800000 },
      { level: 8, profit: 85100, cost: 3500000 },
      { level: 9, profit: 97900, cost: 4500000 },
      { level: 10, profit: 310700, cost: 6000000 },
      { level: 11, profit: 523500, cost: 7500000 },
      { level: 12, profit: 536300, cost: 10000000 },
      { level: 13, profit: 549100, cost: 13000000 },
      { level: 14, profit: 601900, cost: 16000000 },
      { level: 15, profit: 674700, cost: 20000000 },
    ],
    totalProfit: 0,
    icon: '/country_boss.png',
    description:
      'Master communication to effectively negotiate crypto trades, ensuring clarity and success in high-stakes market transactions.',
  },
  {
    title: 'VS. hidden expert',
    level: [
      { level: 1, profit: 7690, cost: 263000 },
      { level: 2, profit: 15000, cost: 526000 },
      { level: 3, profit: 22310, cost: 1052000 },
      { level: 4, profit: 29620, cost: 2104000 },
      { level: 5, profit: 56930, cost: 3000000 },
      { level: 6, profit: 84240, cost: 4000000 },
      { level: 7, profit: 151550, cost: 5000000 },
      { level: 8, profit: 358860, cost: 6200000 },
      { level: 9, profit: 366170, cost: 7800000 },
      { level: 10, profit: 373480, cost: 10000000 },
      { level: 11, profit: 480790, cost: 13000000 },
      { level: 12, profit: 508100, cost: 16000000 },
      { level: 13, profit: 557410, cost: 20000000 },
      { level: 14, profit: 602720, cost: 24000000 },
      { level: 15, profit: 610030, cost: 30000000 },
    ],
    totalProfit: 0,
    icon: '/hiddenexpert.png',
    description:
      'Sharpen your negotiation skills to secure favorable trading terms and maximize profits in competitive cryptocurrency markets.',
  },
  {
    title: 'VS. former champion',
    level: [
      { level: 1, profit: 8300, cost: 375000 },
      { level: 2, profit: 24000, cost: 750000 },
      { level: 3, profit: 39700, cost: 1500000 },
      { level: 4, profit: 55400, cost: 3000000 },
      { level: 5, profit: 71100, cost: 4500000 },
      { level: 6, profit: 186800, cost: 6000000 },
      { level: 7, profit: 302500, cost: 7500000 },
      { level: 8, profit: 318200, cost: 9500000 },
      { level: 9, profit: 433900, cost: 12000000 },
      { level: 10, profit: 549600, cost: 15000000 },
      { level: 11, profit: 565300, cost: 18000000 },
      { level: 12, profit: 601000, cost: 23000000 },
      { level: 13, profit: 606700, cost: 29000000 },
      { level: 14, profit: 772400, cost: 35000000 },
      { level: 15, profit: 828100, cost: 45000000 },
    ],
    totalProfit: 0,
    icon: '/former.png',
    description:
      'Optimize your portfolio by managing assets effectively, ensuring sustainable growth in the volatile cryptocurrency landscape.',
  },
  {
    title: 'VS. dark forces',
    level: [
      { level: 1, profit: 12500, cost: 518000 },
      { level: 2, profit: 37000, cost: 1036000 },
      { level: 3, profit: 61500, cost: 2072000 },
      { level: 4, profit: 86000, cost: 4144000 },
      { level: 5, profit: 110500, cost: 6000000 },
      { level: 6, profit: 335000, cost: 8000000 },
      { level: 7, profit: 359500, cost: 10000000 },
      { level: 8, profit: 484000, cost: 12000000 },
      { level: 9, profit: 508500, cost: 15000000 },
      { level: 10, profit: 533000, cost: 20000000 },
      { level: 11, profit: 657500, cost: 25000000 },
      { level: 12, profit: 782000, cost: 32000000 },
      { level: 13, profit: 816500, cost: 40000000 },
      { level: 14, profit: 861000, cost: 50000000 },
      { level: 15, profit: 955500, cost: 65000000 },
    ],
    totalProfit: 0,
    icon: '/dark.png',
    description:
      'Develop strategies to mitigate risks, protecting your investments while navigating the unpredictable crypto market.',
  },
  {
    title: 'VS. gang boss',
    level: [
      { level: 1, profit: 23000, cost: 620000 },
      { level: 2, profit: 55000, cost: 1240000 },
      { level: 3, profit: 87000, cost: 2480000 },
      { level: 4, profit: 119000, cost: 4960000 },
      { level: 5, profit: 151000, cost: 7000000 },
      { level: 6, profit: 183000, cost: 9500000 },
      { level: 7, profit: 215000, cost: 12000000 },
      { level: 8, profit: 347000, cost: 15000000 },
      { level: 9, profit: 479000, cost: 20000000 },
      { level: 10, profit: 511000, cost: 25000000 },
      { level: 11, profit: 543000, cost: 32000000 },
      { level: 12, profit: 675000, cost: 40000000 },
      { level: 13, profit: 707000, cost: 50000000 },
      { level: 14, profit: 739000, cost: 65000000 },
      { level: 15, profit: 771000, cost: 80000000 },
    ],
    totalProfit: 0,
    icon: '/gangboss.png',
    description:
      'Craft and execute plans that capitalize on market opportunities, positioning your assets for long-term success.',
  },
  {
    title: 'VS. former fighting champion',
    level: [
      { level: 1, profit: 36000, cost: 777000 },
      { level: 2, profit: 62000, cost: 1554000 },
      { level: 3, profit: 88000, cost: 3108000 },
      { level: 4, profit: 114000, cost: 6216000 },
      { level: 5, profit: 140000, cost: 9000000 },
      { level: 6, profit: 266000, cost: 12000000 },
      { level: 7, profit: 392000, cost: 15000000 },
      { level: 8, profit: 418000, cost: 20000000 },
      { level: 9, profit: 544000, cost: 25000000 },
      { level: 10, profit: 570000, cost: 35000000 },
      { level: 11, profit: 696000, cost: 45000000 },
      { level: 12, profit: 722000, cost: 60000000 },
      { level: 13, profit: 748000, cost: 80000000 },
      { level: 14, profit: 774000, cost: 100000000 },
      { level: 15, profit: 800000, cost: 150000000 },
    ],
    totalProfit: 0,
    icon: '/ffchamp.png',
    description:
      'Craft and execute plans that capitalize on market opportunities, positioning your assets for long-term success.',
  },
  {
    title: 'VS. national championship champion',
    level: [
      { level: 1, profit: 57900, cost: 999000 },
      { level: 2, profit: 88700, cost: 1998000 },
      { level: 3, profit: 119500, cost: 3996000 },
      { level: 4, profit: 150300, cost: 7992000 },
      { level: 5, profit: 181100, cost: 10000000 },
      { level: 6, profit: 211900, cost: 14000000 },
      { level: 7, profit: 242700, cost: 18000000 },
      { level: 8, profit: 273500, cost: 23000000 },
      { level: 9, profit: 304300, cost: 30000000 },
      { level: 10, profit: 335100, cost: 40000000 },
      { level: 11, profit: 465900, cost: 50000000 },
      { level: 12, profit: 596700, cost: 65000000 },
      { level: 13, profit: 627500, cost: 85000000 },
      { level: 14, profit: 658300, cost: 100000000 },
      { level: 15, profit: 789100, cost: 150000000 },
    ],
    totalProfit: 0,
    icon: '/champ.png',
    description:
      'Craft and execute plans that capitalize on market opportunities, positioning your assets for long-term success.',
  },
];





const specialCards = [
  {
    title: 'VS. Muay Thai Master',
    profit: 10,
    cost: '500000000',
    icon: '/hunter.webp',
    tagline: 'Withdrawal access',
    description:
      'This is a special card that gives you special access benefits to some of the wallet features on $PNUT FIGHTER',
    class: 'specials1',
  },
  {
    title: 'Early Access',
    profit: 5,
    cost: '100000000',
    icon: '/access.webp',
    tagline: 'Withdrawal access',
    description:
      'With this special card you will stand high reward qualification chances and be among early token holders.',
    class: 'specials2',
  },
  {
    title: 'Balance Booster',
    profit: 50,
    cost: '1000000000',
    icon: '/booster.webp',
    tagline: 'Get more tokens',
    description: 'Get special access to boost your total balance in the boosters section, never a dull moment!',
    class: 'specials3',
  },
  {
    title: 'Token Swap Access',
    profit: 5,
    cost: '200000000',
    icon: '/swap.webp',
    tagline: 'Swap tokens special',
    description: 'This special card gives you access to token swap and withdrawal features in your wallet section.',
    class: 'specials4',
  },
];

export const UserProvider = ({ children }) => {
  const [isGameOpened, setIsGameOpened] = useState(false);
  const [balance, setBalance] = useState(0);
  const [tapBalance, setTapBalance] = useState(0);
  const [level, setLevel] = useState({ id: 1, name: 'Rookie (Level 1)', imgUrl: '/hero1.svg' });
  const [tapValue, setTapValue] = useState({ level: 1, value: 1 });
  const [id, setId] = useState('');
  const [loading, setLoading] = useState(true);
  const [energy, setEnergy] = useState(0);
  const [battery, setBattery] = useState({ level: 1, energy: 500 });
  const [initialized, setInitialized] = useState(false);
  const [refBonus, setRefBonus] = useState(0);
  const [manualTasks, setManualTasks] = useState([]);
  const [showBalance, setShowBalance] = useState(true);
  const [userManualTasks, setUserManualTasks] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [claimedMilestones, setClaimedMilestones] = useState([]);
  const [claimedReferralRewards, setClaimedReferralRewards] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [selectedExchange, setSelectedExchange] = useState({
    id: 'selectex',
    icon: '/exchange.svg',
    name: 'Select exchange',
  });
  const telegramUser = window.Telegram.WebApp.initDataUnsafe?.user;
  const [tapGuru, setTapGuru] = useState(false);
  const [mainTap, setMainTap] = useState(true);
  const [freeGuru, setFreeGuru] = useState(3);
  const [time, setTime] = useState(22);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [lastTime, setLastTime] = useState(null);
  const [claimExchangePoint, setClaimExchangePoint] = useState(true);
  const [selectedCharacter, setSelectedCharacter] = useState({ name: '', avatar: '' });
  const [characterMenu, setCharacterMenu] = useState(false);
  const [fullName, setFullName] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [isAddressSaved, setIsAddressSaved] = useState(false); // State to track if address is saved
  const [coolDownTime, setCoolDownTime] = useState(0);
  const [tappingGuru, setTappingGuru] = useState(0);
  const location = useLocation();
  const [openInfoTwo, setOpenInfoTwo] = useState(true);
  const [openInfoThree, setOpenInfoThree] = useState(true);
  const [lastCheckIn, setLastCheckIn] = useState(null);
  const [checkInDays, setCheckInDays] = useState([]);
  const [error, setError] = useState(null);
  const [showStartOverModal, setShowStartOverModal] = useState(false);
  const [showClaimModal, setShowClaimModal] = useState(false);
  const [userLevels, setUserLevels] = useState(prTeam.map(() => 0)); // Start at level 0
  const [userLevelsMarket, setUserLevelsMarket] = useState(marketTeam.map(() => 0)); // Start at level 0
  const [totalProfit, setTotalProfit] = useState([0, 0, 0, 0, 0, 0]);
  const [totalMarketProfit, setTotalMarketProfit] = useState([0, 0, 0, 0, 0, 0]);
  const [success, setSuccess] = useState(false);
  const [profitHour, setProfitHour] = useState(0);
  const [purchasedCards, setPurchasedCards] = useState([]);
  const [totalCardsProfits, setTotalCardsProfits] = useState(0);
  const [withdrawals, setWithdrawals] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [youtubeTasks, setYoutubeTasks] = useState([]);
  const [userYoutubeTasks, setUserYoutubeTasks] = useState([]);
  // KM
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [activeUserRank, setActiveUserRank] = useState(null);

  // FARM KM:
  const [miningPower, setMiningPower] = useState(0);
  const [miningTotal, setMiningTotal] = useState(0);

  const assets = [
    { symbol: '$PNUT', name: '$PNUTs', balance: balance, icon: '/pnut.png', price: 0.0004348 },
    { symbol: 'USDT', name: 'Tether US', balance: 0, icon: '/tether.webp', price: 1 },
    { symbol: 'TON', name: 'Toncoin', balance: 0, icon: '/ton.png', price: 6.68 },
    { symbol: 'BNB', name: 'BNB', balance: 0, icon: '/bnb2.webp', price: 562.36 },
    { symbol: 'SOL', name: 'Solana', balance: 0, icon: '/solana.png', price: 143.34 },
  ];

  const spinnerLimit = 10;

  const [walletAssets, setWalletAssets] = useState(assets);

  const [spinLimit, setSpinLimit] = useState(spinnerLimit); // New state for spin limit

  // Live mining power update
  useEffect(() => {
    const interval = setInterval(() => {
      const newMiningTotal = miningTotal + miningPower / 3600; // Adds mining power every second
      setMiningTotal(newMiningTotal);
    }, 1000);

    return () => clearInterval(interval);
  }, [miningPower, miningTotal]);

  // Offline mining update
  useEffect(() => {
    const handleOfflineMining = async () => {
      if (!id) return;
      const userRef = doc(db, 'telegramUsers', id);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const lastActive = userData.lastActive?.toDate();
        const now = new Date();
        const timeDifference = (now - lastActive) / 1000; // Time in seconds

        if (timeDifference > 0) {
          const offlineMiningTotal = (miningPower * timeDifference) / 3600;
          const updatedMiningTotal = miningTotal + offlineMiningTotal;

          setMiningTotal(updatedMiningTotal);
          await updateDoc(userRef, {
            miningTotal: updatedMiningTotal,
            lastActive: now,
          });
        }
      }
    };

    handleOfflineMining();
  }, [id, miningPower, miningTotal]);

  const startTimer = useCallback(() => {
    setTime(22); // Set the timer to 22 seconds
    setTapGuru(true); // Activate tapGuru mode
    setMainTap(false); // Disable main tapping during tapGuru mode
    setIsTimerRunning(true); // Mark the timer as running
  
    // Set a timeout to stop tapGuru after 22 seconds
    const timeout = setTimeout(() => {
      setTapGuru(false); // Deactivate tapGuru mode
      setMainTap(true); // Re-enable normal tapping after tapGuru ends
      setIsTimerRunning(false); // Stop the timer
    }, 22000); // 22000ms = 22 seconds
  
    return () => clearTimeout(timeout); // Cleanup timeout if component unmounts
  }, []);
  

  const fetchData = async (userId) => {
    if (!userId) return;
    try {
      const userRef = doc(db, 'telegramUsers', userId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        setBalance(userData.balance);
        setTapBalance(userData.tapBalance);
        setTapValue(userData.tapValue);
        setClaimedMilestones(userData.claimedMilestones || []);
        setClaimedReferralRewards(userData.claimedReferralRewards || []);
        setSelectedExchange(userData.selectedExchange);
        setSelectedCharacter(userData.character);
        setLastCheckIn(userData.lastCheckIn?.toDate() || null);
        setCheckInDays(userData.checkInDays || []);
        const data = userDoc.data().history || {};
        setWithdrawals(data.withdrawals || []);
        setDeposits(data.deposits || []);
        setSwaps(data.swaps || []);
        setFreeGuru(userData.freeGuru);
        setProfitHour(userData.profitHour || 0);
        setUserYoutubeTasks(userData.youtubeTasks || []);
        setWalletAddress(userData.address);
        setShowBalance(userData.showBalance);
        setIsAddressSaved(userData.isAddressSaved);
        setWalletAssets(userData.walletAssets || assets);
        setPurchasedCards(userData.specialCards || []);
        setEnergy(userData.energy);
        // Calculate total profits
        const total = purchasedCards.reduce((acc, card) => acc + card.profit, 0);
        setTotalCardsProfits(total);
        setFullName(userData.fullName);
        const span = userDoc.data().spinLimit ?? 10;
        setSpinLimit(span);
        setBattery(userData.battery);
        setLevel(userData.level);
        setId(userData.userId);
        setRefBonus(userData.refBonus || 0);
        setCompletedTasks(userData.tasksCompleted || []);
        setUserManualTasks(userData.manualTasks || []);
        setReferrals(userData.referrals || []);
        // FARm KM
        setMiningPower(userData.miningPower);
        setMiningTotal(userData.miningTotal);

        await updateActiveTime(userRef);
      }

      const tasksQuerySnapshot = await getDocs(collection(db, 'tasks'));
      const tasksData = tasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTasks(tasksData);

      const manualTasksQuerySnapshot = await getDocs(collection(db, 'manualTasks'));
      const manualTasksData = manualTasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setManualTasks(manualTasksData);

      // Fetch youtubeTasks
      const youtubeTasksQuerySnapshot = await getDocs(collection(db, 'youtubeTasks'));
      const youtubeTasksData = youtubeTasksQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setYoutubeTasks(youtubeTasksData);

      // KM leaderboard start
      const leadersQuerySnapshot = await getDocs(
        query(collection(db, 'leaderBoard'), orderBy('profitHour', 'desc'), limit(150))
      );
      const leadersData = leadersQuerySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setLeaderBoard(leadersData);

      // PROBLEM - Step 1: Query Firestore to find the first 500 users with a balance greater than the current user
      const usersAboveQuery = query(
        collection(db, 'telegramUsers'),
        where('balance', '>', balance),
        orderBy('balance', 'desc'),
        limit(150)
      );

      const querySnapshot = await getDocs(usersAboveQuery);

      // Step 2: If the user is found in the first 500, set the rank as the number of users with a greater balance + 1
      let activeUserRank;
      if (querySnapshot.size > 0) {
        activeUserRank = querySnapshot.size + 1; // Your user is in the top 500
      } else {
        activeUserRank = '150+'; // Your user is not in the top 500
      }

      setActiveUserRank(activeUserRank); // Set the active user rank
      // KM end

      // Fetch settings data
      const settingsDocRef = doc(db, 'settings', 'YrpWnbQOMUNxfXnsUW7yc3PzN4i2'); // Replace with your actual document ID
      const settingsDocSnap = await getDoc(settingsDocRef);

      if (settingsDocSnap.exists()) {
        const settingsData = settingsDocSnap.data();
        setCoolDownTime(settingsData.coolDownTime);
        setTappingGuru(settingsData.tappingGuru);
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
    setLoading(false);
  };

  const sendUserData = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    let referrerId = queryParams.get('ref');
    if (referrerId) {
      referrerId = referrerId.replace(/\D/g, '');
    }

    if (telegramUser) {
      const { id: userId, username, first_name: firstName, last_name: lastName } = telegramUser;
      const finalUsername = username || `${firstName}_${userId}`;
      const fullNamed = `${firstName} ${lastName}`;

      try {
        const userRef = doc(db, 'telegramUsers', userId.toString());
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          fetchData(userId.toString());
          await updateEnergy(userRef, userDoc.data().battery.energy);
          await updateReferrals(userRef);
          setInitialized(true);
          return;
        }

        const userData = {
          userId: userId.toString(),
          username: finalUsername,
          firstName: firstName,
          lastName: lastName,
          fullName: fullNamed,
          totalBalance: 0,
          showBalance: true,
          profitHour: 0,
          spinLimit: 10,
          isAddressSaved: false,
          address: '',
          balance: 0,
          tapBalance: 0,
          lastActive: new Date(),
          character: { name: '', avatar: '/user.webp' },
          freeGuru: 3,
          tapValue: { level: 1, value: 1 },
          level: { id: 1, name: 'Rookie (Level 1)', imgUrl: '/hero1.svg' },
          selectedExchange: { id: 'selectex', icon: '/exchange.svg', name: 'Choose exchange' },
          energy: 500,
          battery: { level: 1, energy: 500 },
          refereeId: referrerId || null,
          referrals: [],
          // FARM KM
          miningPower: 2500,
          miningTotal: 0,
        };

        await setDoc(userRef, userData);
        setEnergy(500);
        setFreeGuru(userData.freeGuru);
        setSelectedCharacter(userData.character);
        setFullName(fullNamed);
        // FARM KM
        setMiningPower(miningPower);
        setMiningTotal(userData.miningTotal);
        // FARKM KM END
        setCharacterMenu(true);
        setSelectedExchange({ id: 'selectex', name: 'Choose exchange', icon: '/exchange.svg' });
        setId(userId.toString());

        if (referrerId) {
          const referrerRef = doc(db, 'telegramUsers', referrerId);
          const referrerDoc = await getDoc(referrerRef);
          if (referrerDoc.exists()) {
            await updateDoc(referrerRef, {
              referrals: arrayUnion({
                userId: userId.toString(),
                username: finalUsername,
                balance: 0,
                level: { id: 1, name: 'Rookie (Level 1)', imgUrl: '/hero1.svg' },
              }),
            });
          }
        }
        setInitialized(true);
        fetchData(userId.toString());
      } catch (error) {
        console.error('Error saving user in Firestore:', error);
      }
    }
  };

  const updateEnergy = async (userRef, batteryValue) => {
    const savedEndTime = localStorage.getItem('endTime');
    const savedEnergy = localStorage.getItem('energy');
    const endTime = new Date(savedEndTime);
    const newTimeLeft = endTime - new Date();
    if (newTimeLeft < 0 && savedEnergy <= 0) {
      try {
        await updateDoc(userRef, { energy: batteryValue });
        setEnergy(batteryValue);
      } catch (error) {
        console.error('Error updating energy:', error);
      }
    }
  };

  const updateActiveTime = async (userRef) => {
    try {
      await updateDoc(userRef, {
        lastActive: new Date(),
      });
      console.log('Active Time Updated');
    } catch (error) {
      console.error('Error updating Active Time:', error);
    }
  };

  const updateSpins = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const lastDate = userData.slotTimeStamp.toDate(); // Convert Firestore timestamp to JS Date
      const formattedDates = lastDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format
      const currentDate = new Date(); // Get the current date
      const formattedCurrentDates = currentDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format

      if (formattedDates !== formattedCurrentDates && userData.spinLimit <= 0) {
        await updateDoc(userRef, {
          spinLimit: 10,
          slotTimeStamp: new Date(),
        });
        setSpinLimit(10);
      }
    }
  };

  const updateReferrals = async (userRef) => {
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();
    const referrals = userData.referrals || [];

    const updatedReferrals = await Promise.all(
      referrals.map(async (referral) => {
        const referralRef = doc(db, 'telegramUsers', referral.userId);
        const referralDoc = await getDoc(referralRef);
        if (referralDoc.exists()) {
          const referralData = referralDoc.data();
          return {
            ...referral,
            balance: referralData.balance,
            level: referralData.level,
          };
        }
        return referral;
      })
    );

    await updateDoc(userRef, { referrals: updatedReferrals });

    const totalEarnings = updatedReferrals.reduce((acc, curr) => acc + curr.balance, 0);
    const refBonus = Math.floor(totalEarnings * 0.1);
    const totalBalance = `${balance}` + refBonus;
    try {
      await updateDoc(userRef, { refBonus, totalBalance, lastActive: new Date() });
    } catch (error) {
      console.error('Error updating referrer bonus:', error);
    }
  };

  const updateUserLevel = async (userId, newTapBalance) => {
    let newLevel = { id: 1, name: 'Rookie (Level 1)', imgUrl: '/hero1.svg' };

    if (newTapBalance >= 5000 && newTapBalance < 100000) {
      newLevel = { id: 2, name: 'Warrior (Level 2)', imgUrl: '/hero2.svg' };
    } else if (newTapBalance >= 100000 && newTapBalance < 1000000) {
      newLevel = { id: 3, name: 'Legend (Level 3)', imgUrl: '/hero3.svg' };
    } else if (newTapBalance >= 1000000 && newTapBalance < 5000000) {
      newLevel = { id: 4, name: 'Gladiator (Level 4)', imgUrl: '/hero4.svg' };
    } else if (newTapBalance >= 5000000 && newTapBalance < 25000000) {
      newLevel = { id: 5, name: 'Master (Level 5)', imgUrl: '/hero5.svg' };
    } else if (newTapBalance >= 25000000 && newTapBalance >= 100000000) {
      newLevel = { id: 6, name: 'Titan (Level 6)', imgUrl: '/hero6.svg' };
    } else if (newTapBalance >= 100000000 && newTapBalance >= 300000000) {
      newLevel = { id: 7, name: 'King (Level 7)', imgUrl: '/hero7.svg' };
    } else if (newTapBalance >= 300000000 && newTapBalance >= 1000000000) {
      newLevel = { id: 8, name: 'Angel (Level 8)', imgUrl: '/hero8.svg' };
    } else if (newTapBalance >= 1000000000 && newTapBalance >= 5000000000) {
      newLevel = { id: 9, name: 'God (Level 9)', imgUrl: '/hero9.svg' };
    } else if (newTapBalance >= 10000000000) {
      newLevel = { id: 10, name: 'Immortal (Level 10)', imgUrl: '/hero10.svg' };
    }

    if (newLevel.id !== level.id) {
      setLevel(newLevel);
      const userRef = doc(db, 'telegramUsers', userId);
      await updateDoc(userRef, { level: newLevel });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const userRef = doc(db, 'telegramUsers', id.toString());
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();

        // Handle prTeam
        if (userData.prTeam) {
          const updatedLevels = prTeam.map((team) => {
            const teamData = userData.prTeam.find((t) => t.title === team.title);
            return teamData ? teamData.level : 0;
          });

          const updatedProfits = prTeam.map((team) => {
            const teamData = userData.prTeam.find((t) => t.title === team.title);
            return teamData ? teamData.totalProfit : 0;
          });

          setUserLevels(updatedLevels);
          setTotalProfit(updatedProfits);
        }

        // Handle marketTeam
        if (userData.marketTeam) {
          const updatedLevelsMarket = marketTeam.map((market) => {
            const marketData = userData.marketTeam.find((t) => t.title === market.title);
            return marketData ? marketData.level : 0;
          });

          const updatedMarketProfits = marketTeam.map((market) => {
            const marketData = userData.marketTeam.find((t) => t.title === market.title);
            return marketData ? marketData.totalMarketProfit : 0;
          });

          setUserLevelsMarket(updatedLevelsMarket);
          setTotalMarketProfit(updatedMarketProfits);
        }
      } else {
        console.error('User document does not exist');
      }
    };

    fetchUserData();
  }, [id]);

  const checkAndUpdateFreeGuru = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();
      const lastDate = userData.timeSta.toDate(); // Convert Firestore timestamp to JS Date
      const formattedDates = lastDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format
      const currentDate = new Date(); // Get the current date
      const formattedCurrentDates = currentDate.toISOString().split('T')[0]; // Get the date part in YYYY-MM-DD format

      if (formattedDates !== formattedCurrentDates && userData.freeGuru <= 0) {
        await updateDoc(userRef, {
          freeGuru: 3,
          timeSta: new Date(),
        });
        setFreeGuru(3);
      }
    }
  };

  useEffect(() => {
    const rewards = document.getElementById('reelsActivities');
    const rewardsTwo = document.getElementById('reels2Activities');

    if (location.pathname === '/rewards' || location.pathname === '/checkin') {
      rewards.style.background = '#935EDB';
      rewards.style.color = '#fff';
      rewardsTwo.style.color = '#fff';
      rewards.style.height = '60px';
      rewards.style.marginTop = '4px';
      rewards.style.paddingLeft = '6px';
      rewards.style.paddingRight = '6px';
    } else {
      rewards.style.background = '';
      rewards.style.color = '';
      rewards.style.height = '';
      rewards.style.marginTop = '';
      rewardsTwo.style.color = '';
      rewards.style.paddingLeft = '';
      rewards.style.paddingRight = '';
    }
  }, [location.pathname]);

  useEffect(() => {
    // Fetch the remaining clicks from Firestore when the component mounts
    const fetchRemainingClicks = async () => {
      if (id) {
        const userRef = doc(db, 'telegramUsers', id.toString());
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFreeGuru(userData.freeGuru || 0);
        }
      }
    };

    fetchRemainingClicks();
  }, [id]);

  useEffect(() => {
    // Calculate the new balance by adding balance and refBonus
    const newBalance = balance + refBonus;

    // Find the current '$PDOGE' token in walletAssets
    const maxToken = walletAssets.find((asset) => asset.symbol === '$PNUT');

    // Check if maxToken exists and if its balance is different from the newBalance
    if (maxToken && maxToken.balance !== newBalance) {
      // Update the balance for the 'LYYC' token
      setWalletAssets((prevAssets) =>
        prevAssets.map((asset) => (asset.symbol === '$PNUT' ? { ...asset, balance: newBalance } : asset))
      );
    }
  }, [balance, refBonus, walletAssets]);

  useEffect(() => {
    const checkLastCheckIn = async () => {
      if (!id) return;

      try {
        const userDocRef = doc(db, 'telegramUsers', id);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const now = new Date();

          const lastCheckInDate = userData.lastCheckIn?.toDate();

          if (lastCheckInDate) {
            const lastCheckInMidnight = new Date(lastCheckInDate);
            lastCheckInMidnight.setHours(0, 0, 0, 0);

            const todayMidnight = new Date(now);
            todayMidnight.setHours(0, 0, 0, 0);

            const daysSinceLastCheckIn = Math.floor((todayMidnight - lastCheckInMidnight) / (1000 * 60 * 60 * 24));

            if (daysSinceLastCheckIn === 1) {
              // Last check-in was yesterday, prompt user to claim today's bonus
              setShowClaimModal(true);
            } else if (daysSinceLastCheckIn > 1) {
              // User missed a day, show the start over modal
              setShowStartOverModal(true);
            }
          } else {
            // First time check-in, set the check-in modal to be shown
            setShowClaimModal(true);
          }
        }
      } catch (err) {
        console.error('Error during initial check-in:', err);
        setError('An error occurred while checking your last check-in.');
      }
    };

    checkLastCheckIn();
  }, [id, setCheckInDays, setError]);

  useEffect(() => {
    if (id) {
      checkAndUpdateFreeGuru();
    }
    if (selectedCharacter.name === '') {
      setCharacterMenu(true);
    } else {
      setCharacterMenu(false);
    }
    updateSpins();
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    sendUserData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (id) {
      updateUserLevel(id, tapBalance);
    }
    // eslint-disable-next-line
  }, [tapBalance, id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <UserContext.Provider
      value={{
        balance,
        specialCards,
        fullName,
        youtubeTasks,
        setYoutubeTasks,
        userYoutubeTasks,
        setUserYoutubeTasks,
        purchasedCards,
        withdrawals,
        setWithdrawals,
        deposits,
        setDeposits,
        swaps,
        setSwaps,
        walletAssets,
        setWalletAssets,
        setPurchasedCards,
        totalCardsProfits,
        setTotalCardsProfits,
        userLevelss,
        success,
        setSuccess,
        userLevels,
        setUserLevels,
        totalMarketProfit,
        setTotalMarketProfit,
        userLevelsMarket,
        setUserLevelsMarket,
        prTeam,
        marketTeam,
        totalProfit,
        setTotalProfit,
        profitHour,
        setProfitHour,
        showStartOverModal,
        setShowStartOverModal,
        showClaimModal,
        setShowClaimModal,
        spinLimit,
        setSpinLimit,
        lastCheckIn,
        setLastCheckIn,
        checkInDays,
        setCheckInDays,
        error,
        setError,
        showBalance,
        setShowBalance,
        openInfoTwo,
        setOpenInfoTwo,
        openInfoThree,
        setOpenInfoThree,
        setFullName,
        coolDownTime,
        setCoolDownTime,
        tappingGuru,
        setTappingGuru,
        lastTime,
        walletAddress,
        setWalletAddress,
        isAddressSaved,
        setIsAddressSaved,
        selectedCharacter,
        setSelectedCharacter,
        characterMenu,
        setCharacterMenu,
        setLastTime,
        claimExchangePoint,
        setClaimExchangePoint,
        battery,
        freeGuru,
        setFreeGuru,
        isTimerRunning,
        setIsTimerRunning,
        time,
        setTime,
        startTimer,
        setBattery,
        tapGuru,
        setTapGuru,
        mainTap,
        setMainTap,
        selectedExchange,
        setSelectedExchange,
        tapValue,
        setTapValue,
        tapBalance,
        setTapBalance,
        level,
        energy,
        setEnergy,
        setBalance,
        setLevel,
        loading,
        setLoading,
        id,
        setId,
        sendUserData,
        leaderBoard,
        setLeaderBoard,
        activeUserRank,
        setActiveUserRank,
        initialized,
        setInitialized,
        refBonus,
        setRefBonus,
        manualTasks,
        setManualTasks,
        userManualTasks,
        setUserManualTasks,
        tasks,
        setTasks,
        completedTasks,
        setCompletedTasks,
        claimedMilestones,
        setClaimedMilestones,
        referrals,
        claimedReferralRewards,
        setClaimedReferralRewards,
        isGameOpened,
        setIsGameOpened,
        // FARM KM
        miningPower,
        setMiningPower,
        miningTotal,
        setMiningTotal,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
